<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/locale/pl';
    import {decimal, maxLength, minLength, required} from 'vuelidate/lib/validators'
    import Vue from "vue";
    import EventBus from '../../event-bus'

    import Repository from "../../app/repository/repository-factory";

    const WalletRepository = Repository.get("WalletRepository");
    const greaterThanZero = (value) => value > 0;

    export default {
        components: { DatePicker },
        props: {
            typeAccount: {
                type: Number,
                default: null
            },
            walletUuid: {
                type: String,
                required: true
            },
            payIn: {
                type: Boolean,
                default: true
            },
            payOut: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                inProgressPayIn: false,
                inProgressPayOut: false,
                preloader: true,
                submittedPayIn: false,
                submittedPayOut: false,
                form: {
                    payIn: {
                        typeAccount: this.typeAccount,
                        walletUuid: this.walletUuid,
                        amount: '',
                        time: new Date().toISOString().slice(0,10),
                        payer: '',
                        depository: '',
                        comments: ''
                    },
                    payOut: {
                        typeAccount: this.typeAccount,
                        walletUuid: this.walletUuid,
                        amount: '',
                        time: new Date().toISOString().slice(0,10),
                        documentDetails: '',
                        comments: ''
                    }
                }
            }
        },
        validations: {
            form: {
                payIn: {
                    amount: {required, decimal, greaterThanZero, maxLength: maxLength(12) },
                    time: {required},
                    payer: {required, minLength: minLength(3), maxLength: maxLength(250)},
                    depository: { maxLength: maxLength(255) }
                },
                payOut: {
                    amount: {required, decimal, greaterThanZero, maxLength: maxLength(12)},
                    time: {required},
                    documentDetails: {required, minLength: minLength(3), maxLength: maxLength(255)},
                    comments: { maxLength: maxLength(500) },
                },
            }
        },
        methods: {
            sendPayOut() {
                this.formSubmitPayOut();

                if (this.$v.form.payOut.$error === true) {
                    return false;
                }
                this.inProgressPayOut = true;

                WalletRepository.payOut(this.form.payOut).then(() => {
                    this.$root.$emit('bv::refresh::table', 'table-wo-' + this.typeAccount);
                    EventBus.$emit('changeWalletSubaccount', this.form.payOut);
                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES! ",
                        text: 'Wypłata została dodana!',
                        showConfirmButton: false,
                        timer: 1500,
                        onClose: () => {
                            this.submittedPayOut = false;
                            this.form.payOut.amount = '';
                            this.form.payOut.documentDetails = '';
                            this.form.payOut.comments = ''
                        }
                    });
                    this.inProgressPayOut = false;
                }).catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true
                    });
                    this.inProgressPayOut = false;
                })
            },
            sendPayIn() {
                this.formSubmitPayIn();

                if (this.$v.form.payIn.$error === true) {
                    return false;
                }

                this.inProgressPayIn = true;

                WalletRepository.payIn(this.form.payIn).then(() => {
                    this.$root.$emit('bv::refresh::table', 'table-wo-' + this.typeAccount);
                    EventBus.$emit('changeWalletSubaccount', this.form.payIn);
                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES! ",
                        text: 'Wpłata została dodana!',
                        showConfirmButton: false,
                        timer: 1500,
                        onClose: () => {

                            this.submittedPayIn = false;
                            this.form.payIn.amount = '';
                            this.form.payIn.payer = '';
                            this.form.payIn.depository = '';
                            this.form.payIn.comments = ''
                        }
                    });
                    this.inProgressPayIn = false;
                }).catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    this.inProgressPayIn = false;
                })
            },
            formSubmitPayOut() {
                this.submittedPayOut = true;
                this.$v.form.payOut.$touch()
            },
            formSubmitPayIn() {
                this.submittedPayIn = true;
                this.$v.form.payIn.$touch()
            }
        }
    };
</script>

<template>
    <div class="row">
        <div class="col-lg-6">
            <template v-if="payOut === true">
                <b-overlay :show="inProgressPayOut" rounded="sm">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title"><i class="text-danger bx bx-bookmark-minus"></i> Wypłata</h4>
                            <p class="card-title-desc"></p>
                            <form class="needs-validation" @submit.prevent="formSubmitPayOut">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                    :id="'payOut-amount-' + typeAccount"
                                                    v-model="form.payOut.amount"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Kwota wypłaty"
                                                    value="Mark"
                                                    :class="{ 'is-invalid': submittedPayOut && $v.form.payOut.amount.$error }"
                                            />
                                            <div v-if="submittedPayOut && $v.form.payOut.amount.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.payOut.amount.required">Ta wartość jest wymagana.</span>
                                                <span v-if="!$v.form.payOut.amount.decimal">Wpisz wartość liczbową (np. 200.00).</span>
                                                <span v-if="!$v.form.payOut.amount.greaterThanZero">Wartość musi być większa od 0.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <date-picker
                                                    :id="'payOut-time-' + typeAccount"
                                                    v-model="form.payOut.time"
                                                    :first-day-of-week="1"
                                                    value-type="format"
                                                    placeholder="Data wypłaty"
                                                    :class="{ 'is-invalid': submittedPayOut && $v.form.payOut.time.$error }"
                                                    lang="pl">
                                            </date-picker>
                                            <div v-if="submittedPayOut && $v.form.payOut.time.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.payOut.time.required">Ta wartość jest wymagana.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input
                                                    :id="'payOut-documentDetails-' + typeAccount"
                                                    v-model="form.payOut.documentDetails"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Typ i numer dokumentu"
                                                    :class="{ 'is-invalid': submittedPayOut && $v.form.payOut.documentDetails.$error }"
                                            />
                                            <div v-if="submittedPayOut && $v.form.payOut.documentDetails.$error"
                                                 class="invalid-feedback">
                                                <span v-if="!$v.form.payOut.documentDetails.required">Ta wartość jest wymagana.</span>
                                                <span v-if="!$v.form.payOut.documentDetails.minLength">Wartość musi zawierać minimum 3 znaki.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input
                                                    :id="'payOut-comments-' + typeAccount"
                                                    v-model="form.payOut.comments"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Uwagi"
                                                    :class="{ 'is-invalid': submittedPayOut && $v.form.payOut.comments.$error }"
                                            />
                                            <div v-if="submittedPayOut && $v.form.payOut.comments.$error"
                                                 class="invalid-feedback">
                                                <span v-if="!$v.form.payOut.comments.required">Ta wartość jest wymagana.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger" v-on:click="sendPayOut" type="submit">Wypłać</button>
                            </form>
                        </div>
                    </div>
                </b-overlay>
            </template>
            <!-- end card -->
        </div>
        <!-- end col -->
        <div class="col-lg-6">
            <template v-if="payIn === true">
                <b-overlay :show="inProgressPayIn" rounded="sm">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title"><i class="text-success bx bx-bookmark-plus"></i> Wpłata </h4>
                            <p class="card-title-desc"></p>
                            <form class="needs-validation" @submit.prevent="formSubmitPayIn">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                    :id="'payIn-amount-' + typeAccount"
                                                    v-model="form.payIn.amount"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Kwota wpłaty"
                                                    value="Mark"
                                                    :class="{ 'is-invalid': submittedPayIn && $v.form.payIn.amount.$error }"
                                            />
                                            <div v-if="submittedPayIn && $v.form.payIn.amount.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.payIn.amount.required">Ta wartość jest wymagana.</span>
                                                <span v-if="!$v.form.payIn.amount.decimal">Wpisz wartość liczbową (np. 200.00).</span>
                                                <span v-if="!$v.form.payOut.amount.greaterThanZero">Wartość musi być większa od 0.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <date-picker
                                                    :id="'payIn-time-' + typeAccount"
                                                    v-model="form.payIn.time"
                                                    :first-day-of-week="1"
                                                    value-type="format"
                                                    placeholder="Data wpłaty"
                                                    :class="{ 'is-invalid': submittedPayIn && $v.form.payIn.time.$error }"
                                                    lang="pl">
                                            </date-picker>
                                            <div v-if="submittedPayIn && $v.form.payIn.time.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.payIn.time.required">Ta wartość jest wymagana.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input
                                                    :id="'payIn-payer-' + typeAccount"
                                                    v-model="form.payIn.payer"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Osoba wpłacająca"
                                                    :class="{ 'is-invalid': submittedPayIn && $v.form.payIn.payer.$error }"
                                            />
                                            <div v-if="submittedPayIn && $v.form.payIn.payer.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.payIn.payer.required">Ta wartość jest wymagana.</span>
                                                <span v-if="!$v.form.payIn.payer.minLength">Wartość musi zawierać minimum 3 znaki.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input
                                                    :id="'payIn-depository-' + typeAccount"
                                                    v-model="form.payIn.depository"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Tytuł wpłaty"
                                                    :class="{ 'is-invalid': submittedPayIn && $v.form.payIn.depository.$error }"
                                            />
                                            <div v-if="submittedPayIn && $v.form.payIn.depository.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.payIn.depository.required">Ta wartość jest wymagana.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-success" v-on:click="sendPayIn" type="submit">Wpłać</button>
                            </form>
                        </div>
                    </div>
                </b-overlay>
            </template>
        </div>
    </div>
</template>
