<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import WalletAmountOperationCorrect from '@/components/subaccount/wallet-amount-operation-correct'
import WalletDetailsOperationCorrect from '@/components/subaccount/wallet-details-operation-correct'
import WalletTimeOperationCorrect from '@/components/subaccount/wallet-time-operation-correct'
import WalletOperationInfo from '@/components/subaccount/wallet-operation-info'

import Repository from "../../app/repository/repository-factory";
import EventBus from "../../event-bus";

const WalletRepository = Repository.get("WalletRepository");

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    excelFilename: {
      type: String,
      required: true
    },
    walletUuid: {
      type: String,
      required: true
    },
    walletVersion: {
      type: Number,
      required: true
    },
    typeAccount: {
      type: Number,
      default: 0
    }
  },
  components: {DatePicker, WalletAmountOperationCorrect, WalletDetailsOperationCorrect, WalletTimeOperationCorrect, WalletOperationInfo},
  data() {
    return {
      isBusy: false,
      excelInProgress: false,
      modalInfo: {
        showModal: false,
        indexItemToShowInModal: null,
      },
      modalCorrect: {
        showModal: false,
        indexItemToShowInModal: null,
      },
      modalCorrectDetails: {
        showModal: false,
        indexItemToShowInModal: null,
      },
      modalTimeCorrect: {
        showModal: false,
        indexItemToShowInModal: null,
      },
      title: "Lista podopiecznych",
      totalRows: 1,
      items: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: "",
      filters: {
        dateFrom: null,
        dateTo: null,
        typeOperation: null
      },
      filterOn: ['dateFrom', 'dateTo'],
      sortBy: 'createdAt',
      sortDesc: true,
      fields: [],
      typeOperations: [
        {value: null, text: 'Wpłaty i wypłaty'},
        {value: 1, text: 'Wpłaty'},
        {value: 2, text: 'Wypłaty'},
      ],
    };
  },
  created() {
    EventBus.$on('operationAmountCorrected', () => {
      this.modalCorrect.showModal = false;
      this.modalCorrect.indexItemToShowInModal = null;
    });
    EventBus.$on('operationDetailsCorrected', () => {
      this.modalCorrectDetails.showModal = false;
      this.modalCorrectDetails.indexItemToShowInModal = null;
    })

  },
  watch: {
    filters: {
      handler: function () {
        this.$refs[this.name].refresh()
      },
      deep: true
    }
  },
  methods: {
    showModalInfoFn(index) {
      this.modalInfo.showModal = true;
      this.modalInfo.indexItemToShowInModal = index;
    },
    showModalCorrectFn(index) {
      this.modalCorrect.showModal = true;
      this.modalCorrect.indexItemToShowInModal = index;
    },
    showModalCorrectDetailsFn(index) {
      this.modalCorrectDetails.showModal = true;
      this.modalCorrectDetails.indexItemToShowInModal = index;
    },
    showModalTimeCorrectFn(index) {
      this.modalTimeCorrect.showModal = true;
      this.modalTimeCorrect.indexItemToShowInModal = index;
    },
    getExcel: function () {
      this.excelInProgress = true;
      const parameters = '?walletUuid=' + this.walletUuid + '&typeAccount=' + this.typeAccount +
        '&search=' + this.filter + '&dateFrom=' + this.filters.dateFrom + '&dateTo=' + this.filters.dateTo +
        '&typeOperation=' + this.filters.typeOperation;

      const promise = WalletRepository.getGridOperationsExcel(parameters);

      return promise.then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Operacje_' + this.excelFilename + '.xlsx');
        document.body.appendChild(link);
        link.click();
        this.excelInProgress = false;
      }).catch(error => {
        console.log(error);
        this.excelInProgress = false;
      })
    },
    axiosProvider(ctx) {
      const parameters = '?walletUuid=' + this.walletUuid + '&typeAccount=' + this.typeAccount +
        '&page=' + ctx.currentPage + '&maxItems=' + ctx.perPage + '&search=' + this.filter +
        '&orderBy=' + this.sortBy + '&orderDesc=' + this.sortDesc +
        '&dateFrom=' + this.filters.dateFrom + '&dateTo=' + this.filters.dateTo +
        '&typeOperation=' + this.filters.typeOperation;

      const promise = WalletRepository.getGridOperations(parameters);

      return promise.then((data) => {
        const fields = data.data.info.fields;

        this.totalRows = data.data.info.countAllItems;

        let fieldsArray = [];
        Object.entries(fields).forEach(([key, val]) => {
          if (val.disabled !== true) {
            val.key = key;
            fieldsArray.push(val);
          }
        });

        fieldsArray.push({'key': 'action', 'label': "Info"});
        this.fields = fieldsArray;
        this.items = data.data.data;

        return data.data.data || []
      }).catch(error => {
        console.log(error);
        return []
      })
    },
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body bg">
          <h4 class="card-title"><i class="text-secondary bx bx-book-bookmark"></i> Lista operacji</h4>
          <p class="card-title-desc"></p>
          <div class="row mb-2">
            <div class="col-sm-12 col-lg-12">
              <b-form inline>
                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                <div class="ml-2 search-box">
                  <div class="position-relative">
                    <b-form-input v-model="filter" :debounce="350" placeholder="Szukaj..."></b-form-input>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </b-form>
            </div>


          </div>
          <div class="row mb-2">
            <div class="col-sm-2 col-lg-2">
              <b-form inline class="right">
                <date-picker
                  id="dateFrom"
                  name="dateFrom"
                  v-model="filters.dateFrom"
                  :first-day-of-week="1"
                  value-type="format"
                  placeholder="od"
                  lang="pl">
                </date-picker>
              </b-form>
            </div>
            <div class="col-sm-2 col-lg-2">
              <b-form inline>
                <date-picker
                  id="dateTo"
                  name="dateTo"
                  v-model="filters.dateTo"
                  :first-day-of-week="1"
                  value-type="format"
                  placeholder="do"
                  lang="pl">
                </date-picker>
              </b-form>
            </div>
            <div class="col-sm-2 col-lg-2">
              <b-form inline>
                <b-form-select v-model="filters.typeOperation" :options="typeOperations"></b-form-select>
              </b-form>
            </div>
            <div class="col-sm-6 col-lg-6">
              <div class="text-sm-right">
                <button type="button" v-on:click="getExcel"
                        class="btn btn-success waves-effect waves-light mb-2 mr-2">
                  <i class="fas fa-file-excel mr-1"></i> Export do Excela
                  <template v-if="excelInProgress">
                    <b-spinner small class="m-0" variant="light" role="status"
                               type="grow"></b-spinner>
                  </template>
                </button>
              </div>
            </div>
          </div>
          <!-- End search -->
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table :style="'min-height:' + 57 * items.length + 'px'"
                     :id="name"
                     :ref="name"
                     :items="axiosProvider"
                     :fields="fields"
                     responsive="sm"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :filter="filter"
                     :filter-included-fields="filterOn">

              <template v-slot:table-busy>
                <div class="text-center text-black">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Ładowanie danych...</strong>
                </div>
              </template>

              <template v-slot:emptyfiltered>
                <div class="text-center text-black">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Ładowanie danych...</strong>
                </div>
              </template>

              <template v-slot:cell(amountMoney)="data">

                <template v-if="data.item.correct === null">
                  <a v-b-tooltip.hover title="Korekta kwoty">
                    <i class="bx bx-edit mr-2" @click="showModalCorrectFn(data.index)"></i>
                  </a>
                </template>

                <template v-if="data.item.correct !== null">
                  <a v-b-tooltip.hover title="Kwota skorygowana - zobacz szczegóły">
                    <i class="bx bxs-info-circle mr-2 text-danger" @click="showModalInfoFn(data.index)"></i>
                  </a>
                </template>

                <span class="font-weight-bold">
                                    {{ data.value.toFixed(2) }} zł
                                </span>
              </template>

              <template v-slot:cell(time)="data">
                <template v-if="data.item.sourceOperation.sourceTypeName === 'Dane testowe' || data.item.sourceOperation.sourceTypeName === 'Formularz'">
                  <a v-b-tooltip.hover title="Korekta daty">
                    <i class="bx bx-edit mr-2" @click="showModalTimeCorrectFn(data.index)"></i>
                  </a>
                </template>
                {{ data.value }}
              </template>

              <template v-slot:cell(payOutDocumentDetails)="data">
                <a v-b-tooltip.hover title="Korekta tytułu">
                  <i class="bx bx-edit mr-2" @click="showModalCorrectDetailsFn(data.index)"></i>
                </a>

                <span v-if="data.item.typeOperation === 'Wypłata'"> {{ data.item.payOutDocumentDetails }}</span>
                <span v-if="data.item.typeOperation === 'Wpłata'"> {{ data.item.payInDepository }}</span>
              </template>

              <template v-slot:cell(typeOperation)="data">
                                <span class="badge badge-pill badge-soft-success font-size-12"
                                      :class=" { 'badge-soft-danger': data.item.amountMoney < 0,
                                    'badge-soft-success': data.item.amountMoney > 0 }">
                                    {{ data.value }}
                                </span>
              </template>

              <template v-slot:cell(action)="row">
                <ul class="list-inline font-size-20 contact-links mb-0">
                  <li class="list-inline-item px-2">
                    <a v-b-tooltip.hover title="Szczegóły transakcji">
                      <i class="bx bxs-info-circle" @click="showModalInfoFn(row.index)"></i>
                    </a>
                  </li>
                </ul>
              </template>

            </b-table>

            <b-modal v-if="modalCorrect.showModal" v-model="modalCorrect.showModal"
                     title="Korekta kwoty operacji" centered>
              <p class="mb-2">
                Typ operacji:
                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].typeOperation }}</span>
              </p>
              <p class="mb-2">
                Kwota:
                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].amountMoney }} zł</span>
              </p>
              <p class="mb-2">
                Data:
                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].time }}</span>
              </p>
              <p class="mb-2">
                Utworzone przez:
                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].createdBy.name }}</span>
              </p>
              <wallet-amount-operation-correct
                :operation-uuid="items[modalCorrect.indexItemToShowInModal].uuid"
                :wallet-uuid="items[modalCorrect.indexItemToShowInModal].walletUuid"
                :type-account="typeAccount"
                :wallet-version="walletVersion"
              >
              </wallet-amount-operation-correct>

              <template v-slot:modal-footer>
                <b-button variant="primary" @click="modalCorrect.showModal = false">Zamknij
                </b-button>
              </template>
            </b-modal>

            <b-modal v-if="modalTimeCorrect.showModal" v-model="modalTimeCorrect.showModal"
                     title="Korekta daty operacji" centered>
              <p class="mb-2">
                Kwota:
                <span class="font-weight-bold">{{ items[modalTimeCorrect.indexItemToShowInModal].amountMoney }} zł</span>
              </p>
              <p class="mb-2">
                Data:
                <span class="font-weight-bold">{{ items[modalTimeCorrect.indexItemToShowInModal].time }}</span>
              </p>
              <p class="mb-2">
                Tytuł:
                <span class="font-weight-bold"
                      v-if="items[modalTimeCorrect.indexItemToShowInModal].typeOperation === 'Wypłata'"> {{
                    items[modalTimeCorrect.indexItemToShowInModal].payOutDocumentDetails
                  }}</span>
                <span class="font-weight-bold"
                      v-if="items[modalTimeCorrect.indexItemToShowInModal].typeOperation === 'Wpłata'"> {{
                    items[modalTimeCorrect.indexItemToShowInModal].payInDepository
                  }}</span>
              </p>

              <wallet-time-operation-correct
                :operation-uuid="items[modalTimeCorrect.indexItemToShowInModal].uuid"
                :wallet-uuid="items[modalTimeCorrect.indexItemToShowInModal].walletUuid"
                :time-operation="items[modalTimeCorrect.indexItemToShowInModal].time"
                :wallet-version="walletVersion"
              >
              </wallet-time-operation-correct>

              <template v-slot:modal-footer>
                <b-button variant="primary" @click="modalTimeCorrect.showModal = false">Zamknij
                </b-button>
              </template>
            </b-modal>

            <b-modal v-if="modalCorrectDetails.showModal" v-model="modalCorrectDetails.showModal"
                     title="Korekta tytułu operacji" centered>
              <p class="mb-2">
                Typ operacji:
                <span class="font-weight-bold">{{
                    items[modalCorrectDetails.indexItemToShowInModal].typeOperation
                  }}</span>
              </p>
              <p class="mb-2">
                Kwota:
                <span class="font-weight-bold">{{
                    items[modalCorrectDetails.indexItemToShowInModal].amountMoney
                  }} zł</span>
              </p>
              <p class="mb-2">
                Data:
                <span class="font-weight-bold">{{ items[modalCorrectDetails.indexItemToShowInModal].time }}</span>
              </p>
              <p class="mb-2">
                Utworzone przez:
                <span class="font-weight-bold">{{
                    items[modalCorrectDetails.indexItemToShowInModal].createdBy.name
                  }}</span>
              </p>

              <p class="mb-2">
                Tytuł:
                <span class="font-weight-bold"
                      v-if="items[modalCorrectDetails.indexItemToShowInModal].typeOperation === 'Wypłata'"> {{
                    items[modalCorrectDetails.indexItemToShowInModal].payOutDocumentDetails
                  }}</span>
                <span class="font-weight-bold"
                      v-if="items[modalCorrectDetails.indexItemToShowInModal].typeOperation === 'Wpłata'"> {{
                    items[modalCorrectDetails.indexItemToShowInModal].payInDepository
                  }}</span>
              </p>

              <wallet-details-operation-correct
                :operation-uuid="items[modalCorrectDetails.indexItemToShowInModal].uuid"
                :wallet-uuid="items[modalCorrectDetails.indexItemToShowInModal].walletUuid"
                :type-account="typeAccount"
                :wallet-version="walletVersion"
                :type-operation="items[modalCorrectDetails.indexItemToShowInModal].typeOperation"
                :pay-out-document-details="items[modalCorrectDetails.indexItemToShowInModal].payOutDocumentDetails"
                :pay-in-depository="items[modalCorrectDetails.indexItemToShowInModal].payInDepository"
              >
              </wallet-details-operation-correct>

              <template v-slot:modal-footer>
                <b-button variant="primary" @click="modalCorrectDetails.showModal = false">Zamknij
                </b-button>
              </template>

            </b-modal>

            <b-modal v-if="modalInfo.showModal" v-model="modalInfo.showModal"
                     title="Szczegóły transakcji" centered>

              <wallet-operation-info :data="items[modalInfo.indexItemToShowInModal]">
              </wallet-operation-info>

              <template v-slot:modal-footer>
                <b-button variant="primary" @click="modalInfo.showModal = false">Zamknij</b-button>
              </template>
            </b-modal>


          </div>
          <div class="row">
            <div class="col">
              <template v-if="totalRows === 0">
                <div class="d-flex justify-content-center mt-3">
                  <b>Nie znaleziono elementów</b>
                </div>
              </template>
              <template v-if="totalRows > 0">
                <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                  Znaleziono {{ totalRows }} rekordów.
                </div>
              </template>
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="totalRows"
                                :per-page="perPage"
                                first-number last-number></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
