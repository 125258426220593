<script>

import {required} from 'vuelidate/lib/validators'
import Vue from "vue";
import EventBus from '../../event-bus'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';

import Repository from "../../app/repository/repository-factory";

const WalletRepository = Repository.get("WalletRepository");

export default {
  props: {
    typeAccount: {
      type: Number,
      default: 0
    },
    operationUuid: {
      type: String,
      required: true
    },
    walletUuid: {
      type: String,
      required: true
    },
    walletVersion: {
      type: Number,
      required: true
    },
    timeOperation: {
      type: String,
      default: null,
      required: true
    }
  },
  components: {DatePicker},
  data() {
    return {
      preloader: true,
      submitted: false,
      form: {
        newTime: this.timeOperation,
      }
    }
  },
  validations: {
    form: {
      newTime: {required},
    }
  },
  methods: {
    sendCorrect() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      const payload = {
        "newTime": this.form.newTime,
        "walletVersion": this.walletVersion,
        "operationUuid": this.operationUuid,
        "walletUuid": this.walletUuid,

      };

      WalletRepository.correctTimeOperation(this.operationUuid, this.walletUuid, payload).then(() => {
        EventBus.$emit('changeWalletSubaccount', payload);
        EventBus.$emit('operationDetailsCorrected', payload);

        this.$root.$emit('bv::refresh::table', 'table-wo-' + this.typeAccount);

        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Korekta została wykonana pomyślnie!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            this.submitted = false;
          }
        });
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
      })
    },
    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch()
    },
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" @submit.prevent="formSubmit">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <date-picker
                    id="newTime"
                    v-model="form.newTime"
                    :first-day-of-week="1"
                    value-type="format"
                    placeholder="Data"
                    :class="{ 'is-invalid': submitted && $v.form.newTime.$error }"
                    lang="pl">
                  </date-picker>
                  <div v-if="submitted && $v.form.newTime.$error"
                       class="invalid-feedback">
                    <span v-if="!$v.form.newTime.required">Ta wartość jest wymagana.<br></span>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-danger" v-on:click="sendCorrect" type="submit">Koryguj datę</button>
          </form>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
