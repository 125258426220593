<script>

import {decimal, maxLength, minLength, required} from 'vuelidate/lib/validators'
import Vue from "vue";
import EventBus from '../../event-bus'

import Repository from "../../app/repository/repository-factory";

const WalletRepository = Repository.get("WalletRepository");
const equalOrGreaterThanZero = (value) => value >= 0;

export default {
  props: {
    typeAccount: {
      type: Number,
      default: 0
    },
    operationUuid: {
      type: String,
      required: true
    },
    walletUuid: {
      type: String,
      required: true
    },
    walletVersion: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      preloader: true,
      submitted: false,
      form: {
        newAmount: '',
        reasonCorrect: '',
      }
    }
  },
  validations: {
    form: {
      newAmount: {required, decimal, equalOrGreaterThanZero, maxLength: maxLength(12)},
      reasonCorrect: {required, minLength: minLength(3), maxLength: maxLength(250)},
    }
  },
  methods: {
    sendCorrect() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      const payload = {
        "newAmount": this.form.newAmount,
        "reasonCorrect": this.form.reasonCorrect,
        "walletVersion": this.walletVersion,
        "operationUuid": this.operationUuid,
        "walletUuid": this.walletUuid
      };

      WalletRepository.correctAmountOperation(this.operationUuid, this.walletUuid, payload).then(() => {
        EventBus.$emit('changeWalletSubaccount', payload);
        EventBus.$emit('operationAmountCorrected', payload);

        this.$root.$emit('bv::refresh::table', 'table-wo-' + this.typeAccount);

        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Korekta została wykonana pomyślnie!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            this.submitted = false;
            this.form.newAmount = '';
            this.form.reasonCorrect = '';
          }
        });
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
      })
    },
    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch()
    },
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" @submit.prevent="formSubmit">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    id="newAmount"
                    v-model="form.newAmount"
                    type="text"
                    class="form-control"
                    placeholder="Nowa kwota"
                    value="Mark"
                    :class="{ 'is-invalid': submitted && $v.form.newAmount.$error }"
                  />
                  <div v-if="submitted && $v.form.newAmount.$error" class="invalid-feedback">
                    <span v-if="!$v.form.newAmount.required">Ta wartość jest wymagana.<br></span>
                    <span v-if="!$v.form.newAmount.decimal">Wpisz wartość liczbową (np. 200.00).<br></span>
                    <span v-if="!$v.form.newAmount.greaterThanZero">Wartość musi być większa od 0.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    id="reasonCorrect"
                    v-model="form.reasonCorrect"
                    type="text"
                    class="form-control"
                    placeholder="Powód korekty"
                    :class="{ 'is-invalid': submitted && $v.form.reasonCorrect.$error }"
                  />
                  <span class="mt-5 font-size-11">* wykonanie korekty jest jednorazowa. Po jej zatwierdzeniu dalsze, zmiany kwoty nie będą już możliwe!</span>
                  <div v-if="submitted && $v.form.reasonCorrect.$error"
                       class="invalid-feedback">
                    <span v-if="!$v.form.reasonCorrect.required">Ta wartość jest wymagana.<br></span>
                    <span v-if="!$v.form.reasonCorrect.minLength">Wartość musi zawierać minimum 3 znaki.</span>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-danger" v-on:click="sendCorrect" type="submit">Koryguj kwotę</button>
          </form>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
